<template>
  <b-sidebar
    id="add-new-equation-sidebar"
    :visible="isAddNewEquationSidebarActive"
    bg-variant="white"
    sidebar-class="custom-sidebar"
    class="custom-sidebar"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankequation).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("equationAdd") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankequation
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- ingredients -->

          <b-form-group :label="$t('ingredient')" label-for="ingredients">
            <v-select
              id="hammaddeid"
              v-model="blankEquationData.hammaddeid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="
                ingredients.map((x) => ({
                  label: x.ingredientname,
                  value: x.id,
                }))
              "
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <b-form-group :label="$t('nutrient')" label-for="nutrients">
            <v-select
              id="besinMaddesiid"
              v-model="blankEquationData.besinMaddesiid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="
                nutrients.map((x) => ({
                  label: x.nutrientname,
                  value: x.id,
                }))
              "
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <!-- Formul -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="formul"
          >
            <b-form-group :label="$t('formul')" label-for="formul">
              <b-form-input
                id="formul"
                v-model="blankEquationData.formul"
                autofocus
                :state="
                  formValidation(resetblankequation).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sartli -->
          <validation-provider
            #default="validationContext"
            name="conditionally"
          >
            <b-form-group
              :label="$t('conditionally')"
              label-for="conditionally"
            >
              <b-form-checkbox
                id="conditionally"
                v-model="blankEquationData.sartli"
                :state="
                  formValidation(resetblankequation).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- condition1nutrient -->
          <b-form-group
            v-if="blankEquationData.sartli"
            :label="$t('condition1nutrient')"
            label-for="nutrients"
          >
            <v-select
              id="besinMaddesiid"
              v-model="blankEquationData.sartbmid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="
                nutrients.map((x) => ({
                  label: x.nutrientname,
                  value: x.id,
                }))
              "
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <!-- condition1operator -->
          <b-form-group
            v-if="blankEquationData.sartli"
            :label="$t('condition1operator')"
            label-for="operator1"
          >
            <v-select
              id="operators"
              v-model="blankEquationData.sartoperator"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="operators"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <!-- condition1value -->
          <validation-provider
            v-if="blankEquationData.sartli"
            #default="validationContext"
            name="condition1value"
          >
            <b-form-group
              :label="$t('condition1value')"
              label-for="condition1value"
            >
              <b-form-input
                id="condition1value"
                v-model="blankEquationData.sartdeger"
                autofocus
                :state="
                  formValidation(resetblankequation).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- concat -->
          <b-form-group
            v-if="blankEquationData.sartli"
            :label="$t('concat')"
            label-for="operator2"
          >
            <v-select
              id="operators"
              v-model="blankEquationData.sartbirlestir"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[
                { label: $t('empty'), value: 0 },
                { label: $t('and'), value: 1 },
                { label: $t('or'), value: 2 },
              ]"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <!-- condition2nutrient -->
          <b-form-group
            v-if="
              blankEquationData.sartli && blankEquationData.sartbirlestir > 0
            "
            :label="$t('condition2nutrient')"
            label-for="nutrients"
          >
            <v-select
              id="besinMaddesiid"
              v-model="blankEquationData.sartbmid2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="
                nutrients.map((x) => ({
                  label: x.nutrientname,
                  value: x.id,
                }))
              "
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <!-- condition2operator -->
          <b-form-group
            v-if="
              blankEquationData.sartli && blankEquationData.sartbirlestir > 0
            "
            :label="$t('condition2operator')"
            label-for="operator2"
          >
            <v-select
              id="operators"
              v-model="blankEquationData.sartoperator2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="operators"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>

          <!-- condition2value -->
          <validation-provider
            v-if="
              blankEquationData.sartli && blankEquationData.sartbirlestir > 0
            "
            #default="validationContext"
            name="condition2value"
          >
            <b-form-group
              :label="$t('condition2value')"
              label-for="condition2value"
            >
              <b-form-input
                id="condition2value"
                v-model="blankEquationData.sartdeger2"
                autofocus
                :state="
                  formValidation(resetblankequation).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{
                loading ? "İşlem Devam Ediyor. Lütfen Bekleyiniz." : $t("add")
              }}
            </b-button>
            <b-button
              v-if="!loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
        <div>
          <b-table
            small
            responsive="sm"
            :items="nutrients"
            :fields="nutrientTableFields"
            :tbody-tr-class="rowClass"
            @row-clicked="(val) => rowClicked(val)"
          >
            <template #cell(count)="data">
              <b-badge variant="success">
                {{
                  blankEquationData.formul.split("[B" + data.item.kod + "]")
                    .length - 1
                }}</b-badge
              >
            </template>
            <template #cell(nutrientname)="data">
              <small>
                {{ data.item.nutrientname }}
              </small>
            </template>
            <template #cell(kod)="data">
              <small>
                {{ data.item.kod }}
              </small>
            </template>
            <template #cell(birim)="data">
              <small>
                {{ data.item.birim }}
              </small>
            </template>
            <template #cell(remove)="data">
              <small>
                <feather-icon
                  icon="XIcon"
                  class="xIcon"
                  @click.stop="removeNutrient(data.item.kod)"
                ></feather-icon>
              </small>
            </template>
          </b-table>
        </div>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BTable,
  BBadge,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    // BInputGroup,
    // BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // BCardActions,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewEquationSidebarActive",
    event: "update:is-add-new-equation-sidebar-active",
  },
  props: {
    isAddNewEquationSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      required,
      blankEquationData: {
        id: 0,
        hammaddeid: 0,
        besinMaddesiid: 0,
        formul: "",
        sartli: false,
        sira: 0,
        sartbmid: 0,
        sartbmid2: 0,
        sartdeger: 0,
        sartdeger2: 0,
        sartoperator: "=", // =, <, >, <=, >=, <>
        sartoperator2: "=",
        sartbirlestir: 0, // AND, OR
      },
      operators: [
        { label: "=", value: "=" },
        { label: "<", value: "<" },
        { label: ">", value: ">" },
        { label: "<=", value: "<=" },
        { label: ">=", value: ">=" },
        { label: "<>", value: "<>" },
      ],

      ingredients: [],
      nutrients: [],
      nutrientTableFields: [
        { key: "count", label: "" },
        { key: "kod", label: this.$t("code") },
        { key: "nutrientname", label: this.$t("nutrientName") },
        { key: "birim", label: this.$t("unit") },
        { key: "remove", label: "" },
      ],
      formValidation: formValidation,
    };
  },
  async mounted() {
    this.ingredients = await store.dispatch(
      "plantsModule/fetchIngredientsForEsitlikler",
      store.state.app.selectedPlantId
    );
    this.ingredients.unshift({
      id: 0,
      ingredientname: "Genel Formül",
    });
    this.blankEquationData.hammaddeid = 0;
    this.nutrients = await store.dispatch(
      "plantsModule/fetchNutrients",
      store.state.app.selectedPlantId
    );
  },
  methods: {
    async changed(val) {
      if (val == true) this.resetblankequation();
      this.$emit("update:is-add-new-equation-sidebar-active", val);
    },
    rowClass(item, type) {
      if (item && type === "row") {
        if (this.blankEquationData.formul.includes("[B" + item.kod + "]")) {
          return "selected-row";
        } else {
          return "bg-gray-100";
        }
      } else {
        return null;
      }
    },
    rowClicked(val) {
      if (this.blankEquationData.formul.trim().endsWith("]")) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("warning"),
            icon: "CheckIcon",
            variant: "warning",
            text: this.$t("firstSelectAnOperator"),
          },
        });
      } else this.blankEquationData.formul += "[B" + val.kod + "]";
    },
    //remove last nutrient from string
    removeNutrient(val) {
      var bStr = "[B" + val + "]";
      var lastIndex = this.blankEquationData.formul.lastIndexOf(bStr);
      if (lastIndex == -1) {
        return;
      }

      var newStr =
        this.blankEquationData.formul.substring(0, lastIndex) +
        this.blankEquationData.formul.substring(
          lastIndex + bStr.length + 1,
          this.blankEquationData.formul.length
        );
      this.blankEquationData.formul = newStr;
    },
    onSubmit() {
      this.loading = true;
      store
        .dispatch("plantsModule/addNutrientEquation", this.blankEquationData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-equation-sidebar-active", false);
          this.loading = false;
        });
    },
    async resetblankequation() {
      this.blankEquationData = {
        id: 0,
        hammaddeid: null,
        sira: 0,
        besinMaddesiid: 0,
        formul: "",
        sartli: false,
        sartbmid: 0,
        sartbmid2: 0,
        sartdeger: 0,
        sartdeger2: 0,
        sartoperator: "=", // =, <, >, <=, >=, <>
        sartoperator2: "=",
        sartbirlestir: 0, // AND, OR
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-equation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style>
.selected-row {
  background-color: #f5f5f5;
  color: black !important;
}
.selected-row > td {
  color: black !important;
}
</style>

<style>
.custom-sidebar {
  width: 40rem !important;
}
.xIcon:hover {
  cursor: pointer;
  color: red;
  z-index: 1;
}
</style>
