import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import router from "@/router";
export default function useEquationsList() {
  // Use toast
  const toast = useToast();

  const refEquationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "hammadde",
      label: VueI18n.t("ingredientName"),
      sortable: true,
    },
    { key: "besinMaddesi", label: VueI18n.t("nutrientName"), sortable: true },
    { key: "formul", label: VueI18n.t("formula"), sortable: true },
    { key: "edit", label: VueI18n.t("edit") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(15);
  const totalEquations = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const equations = ref(null);
  const allEquations = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refEquationListTable.value
      ? refEquationListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          (localItemsCount ? 1 : 0) +
          perPage.value <
        totalEquations.value
          ? perPage.value * (currentPage.value - 1) +
            (localItemsCount ? 1 : 0) +
            perPage.value
          : totalEquations.value,
      of: totalEquations.value,
    };
  });

  const refetchData = (force) => {
    // refEquationListTable.value.refresh();
    force = force || false;
    fetchEquations(force);
  };

  watch([roleFilter, planFilter, statusFilter], () => {
    refetchData();
  });

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const searchQueryChanged = debounce(() => {
    refetchData();
  }, 200);

  const fetchEquations = async (force) => {
    if (force || searchQuery.value == "" || allEquations.value == null) {
      allEquations.value = await store
        .dispatch("plantsModule/getNutrientEquations")
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: VueI18n.t("xFetchError", {
                value: VueI18n.t("equations"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      equations.value = allEquations.value;
    }
    if (searchQuery.value != "") {
      setTimeout(() => {}, 1000);

      var eq = [];
      for (let index = 0; index < allEquations.value.length; index++) {
        const element = allEquations.value[index];

        if (
          `${element.hammadde}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          `${element.besinMaddesi}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          `${element.formul}`
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        ) {
          eq.push(element);
        }
      }
      equations.value = eq;
    }
    totalEquations.value = equations.value.length;

    return equations.value;
  };

  const deleteEquation = (ctx) => {
    store
      .dispatch("plantsModule/deleteEquation", ctx)
      .then((response) => {
        refetchData(true);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("equation") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "success",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("equation") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchEquations,
    tableColumns,
    perPage,
    currentPage,
    totalEquations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEquationListTable,
    deleteEquation,
    searchQueryChanged,
    refetchData,
    equations,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
